

















































import { Vue, Prop, Component, Mixins, Watch } from 'vue-property-decorator'

import BeatTable from '@/components/BeatTable.vue'
import TilePanel from '@/components/TilePanel.vue'
import TilePanelPlaceholder from '@/components/TilePanelPlaceholder.vue'
import { Dropdowns } from '~/mixins/dropdowns'
import { fetchSingleProducer } from '@/api/producers'
import { BeatFilterType, fetchBeats } from '~/api/beats'
import { UserStore } from '@/store/user'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { AuthStore } from '~/store/auth'
import { IFollowedProducerParams } from '@/api/followed-producers'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { TilePanel, BeatTable, TilePanelPlaceholder } })
export default class ProducerPage extends Mixins(Dropdowns, InfiniteLoadingSetState) {
    @Prop({ type: String }) slug: string

    ParentStateType = ParentStateType
    producerData = {} as IProducer
    producerBeats: IBeat[] = []
    producerPopularBeats: IBeat[] = []

    @UserStore.State('followedProducers') followedProducers: IFollowedProducer[]
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

    get producerInFollowedProducers(): IFollowedProducer {
        // if this current producer is part of the user's followedProducers. Returns IFollowedProducer or null
        if (this.isAuthenticated) return this.followedProducers.find(item => item.producer.id === this.producerData.id)
        return null
    }

    get backgroundImageLink(): string {
        if (this.producerData.image_background_url) return `url(${this.producerData.image_background_url})`
        return `url('./../img/bg-producer-default2.jpg')`
    }

    get producerHasSocialLinks(): boolean {
        const p = this.producerData
        return p.facebook || p.twitter || p.youtube || p.instagram || p.soundcloud ? true : false
    }

    @UserStore.Action('toggleFollowProducer') toggleFollowProducer: (IFollowedProducerParams: IFollowedProducerParams) => void

    toggleProducerMailingListSubscription() {
        this.$store.dispatch('user/toggleProducerMailingListSubscription', {
            producerId: this.producerData.id,
            shouldReceiveEmail: !this.producerInFollowedProducers.should_receive_email
        })
    }

    async fetchProducerAndPopularBeats() {
        this.producerData = await fetchSingleProducer(this.slug)
        this.producerPopularBeats = await fetchBeats({ filterType: BeatFilterType.ProducerPopular, slug: this.slug })
    }

    async updateBeatsTable($state: any, offset: number) {
        // call the store to update the beats
        const beats = await fetchBeats({ filterType: BeatFilterType.Producer, slug: this.slug, offset })
        this.producerBeats.length > 0 ? this.producerBeats.push(...beats) : (this.producerBeats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    fetchEverything() {
        this.fetchProducerAndPopularBeats()
        this.updateBeatsTable(null, 0)
    }

    @Watch('slug')
    onSlugChange(newValue: string) {
        // reset beats list
        this.producerBeats = []
        this.fetchEverything()
    }

    @Watch('producerData')
    onProducerChange(newValue: IProducer) {
        if (newValue.id) {
            // track page view
            Vue.$axios.post(`/api/track_page_visit/`, {
                producer_id: newValue.id,
                from_elite_page: false
            })
        }
    }

    mounted() {
        this.fetchEverything()
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c(
      "header",
      {
        staticClass:
          "header header--lg header__gradient header__gradient--fromBottom header__gradient--fromLeft",
        style: { "background-image": _vm.backgroundImageLink },
      },
      [
        _c("div", { staticClass: "container container--sm flexbox" }, [
          _c("div", { staticClass: "producer" }, [
            _vm.producerData && _vm.producerData.image_logo_url
              ? _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.producerData.image_logo_url,
                      expression: "producerData.image_logo_url",
                    },
                  ],
                  staticClass: "producer__logo",
                })
              : _vm._e(),
            _c("div", [
              _c("h2", { staticClass: "h2--small-uppercase" }, [
                _vm._v("PRODUCER"),
              ]),
              _c("h1", { staticClass: "producer__name" }, [
                _vm._v(
                  _vm._s(
                    _vm.producerData
                      ? _vm.producerData.display_name
                      : "loading…"
                  )
                ),
              ]),
              _vm.producerData
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeMenuDropdown,
                          expression: "closeMenuDropdown",
                        },
                      ],
                      staticClass: "producer__wrapperButtons",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn--white",
                          on: {
                            click: function ($event) {
                              return _vm.toggleFollowProducer({
                                producerId: _vm.producerData.id,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.producerInFollowedProducers
                                ? "Following"
                                : "Follow"
                            )
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "producer__iconDots",
                          on: { click: _vm.toggleMenuDropdown },
                        },
                        [_c("icon", { attrs: { name: "open-menu" } })],
                        1
                      ),
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.menuDropDown,
                                expression: "menuDropDown",
                              },
                            ],
                            staticClass:
                              "dropdown__menu dropdown__menu--minWidth",
                          },
                          [
                            _c("li", { staticClass: "dropdown__item" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "mailto:" + _vm.producerData.email,
                                  },
                                },
                                [
                                  _vm._v(
                                    "Email " + _vm._s(_vm.producerData.email)
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "dropdown__item" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/s3/show_license_pdf/" + _vm.slug + "/",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("View License Agreement")]
                              ),
                            ]),
                            _c("hr", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.producerHasSocialLinks,
                                  expression: "producerHasSocialLinks",
                                },
                              ],
                              staticClass: "dropdown__divider",
                            }),
                            _vm.producerData.facebook
                              ? _c("li", { staticClass: "dropdown__item" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.producerData.facebook,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Facebook")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.producerData.twitter
                              ? _c("li", { staticClass: "dropdown__item" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.producerData.twitter,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Twitter")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.producerData.youtube
                              ? _c("li", { staticClass: "dropdown__item" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.producerData.youtube,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("YouTube")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.producerData.instagram
                              ? _c("li", { staticClass: "dropdown__item" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.producerData.instagram,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Instagram")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.producerData.soundcloud
                              ? _c("li", { staticClass: "dropdown__item" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.producerData.soundcloud,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Soundcloud")]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm.producerInFollowedProducers
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.producerInFollowedProducers
                                    .should_receive_email
                                    ? "Unsubscribe from email updates on beat releases"
                                    : "Subscribe to email updates on beat releases",
                                  expression:
                                    "producerInFollowedProducers.should_receive_email ? 'Unsubscribe from email updates on beat releases' : 'Subscribe to email updates on beat releases' ",
                                },
                              ],
                              staticClass: "producer__iconAlert",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleProducerMailingListSubscription()
                                },
                              },
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  name: _vm.producerInFollowedProducers
                                    .should_receive_email
                                    ? "alert-on"
                                    : "alert-off",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "section",
      { staticClass: "section", staticStyle: { "margin-bottom": "50px" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
            _vm._v("Most Popular Beats"),
          ]),
          _c(
            "div",
            { staticClass: "grid grid__col5 grid--scroll-lte-tablet" },
            [
              _vm._l(5, function (n) {
                return _vm.producerPopularBeats.length === 0
                  ? _c("tile-panel-placeholder", { key: n })
                  : _vm._e()
              }),
              _vm._l(_vm.producerPopularBeats, function (beat, index) {
                return _c("tile-panel", {
                  key: beat.id,
                  attrs: {
                    beat: beat,
                    index: index,
                    "loaded-beats": _vm.producerPopularBeats,
                  },
                })
              }),
            ],
            2
          ),
        ]),
      ]
    ),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
            _vm._v("Production Catalog"),
          ]),
          _c("BeatTable", {
            attrs: {
              parentState: _vm.ParentStateType.Producer,
              beatPlaylist: _vm.producerBeats,
            },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.producerBeats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }